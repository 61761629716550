<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        An absorption spectrum is a plot of <b>&nbsp;[A]&nbsp;</b> vs <b>&nbsp;[B]</b>.
        <stemble-latex content="$\lambda_\text{max}$" />
        is the <b>&nbsp;[C]</b>.
      </p>
      <p class="mb-2 pl-6">
        [A]:
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2 pl-6">
        [B]:
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-6 pl-6">
        [C]:
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        A Beer's Law plot is a plot of <b>&nbsp;[D]&nbsp;</b> vs <b>&nbsp;[E]</b>. Beer's Law is
        <b>&nbsp;[F]&nbsp;</b> The slope of Beer's Law is <b>&nbsp;[G]&nbsp;</b> and the y-intercept
        is <b>&nbsp;[H]</b>.
      </p>

      <p class="mb-2 pl-6">
        [D]:
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2 pl-6">
        [E]:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2 pl-6">
        [F]:
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items3"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2 pl-6">
        [G]:
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-6 pl-6">
        [H]:
        <v-select
          v-model="inputs.input8"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        If the absorbance is measured at a <stemble-latex content="$\lambda$" /> different than
        <stemble-latex content="$\lambda_\text{max}$," /> the absorbance at that different
        <stemble-latex content="$\lambda$" /> is <b>&nbsp;[I]&nbsp;</b> the absorbance at
        <stemble-latex content="$\lambda_\text{max}$." />
      </p>

      <p class="mb-6 pl-6">
        [I]:
        <v-select
          v-model="inputs.input9"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items5"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        If the concentration of the first sample of dye 1 is less than the concentration of a second
        sample of dye 1, which sample has the highest absorbance?
      </p>

      <p class="mb-6 pl-6">
        j)
        <v-select
          v-model="inputs.input10"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items6"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        If a solution contains a yellow dye and a blue dye, the absorption spectrum will contain
        <b>&nbsp;[K]</b>.
      </p>
      <p class="mb-3 pl-6">
        [K]:
        <v-select
          v-model="inputs.input11"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items7"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S1Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
        input11: null,
      },
      items1: [
        {text: 'Color', value: 'color'},
        {text: 'Absorbance', value: 'absorbance'},
        {text: 'Wavelength', value: 'wavelength'},
        {text: 'Concentration', value: 'concentration'},
      ],
      items2: [
        {text: 'Longest wavelength from the spectrum.', value: 'fromSpectrum'},
        {
          text: 'Minimum Absorbance value recorded from the longest wavelength.',
          value: 'minimumAbs',
        },
        {
          text: 'Max wavelength recorded from the spectrum where the signal is strongest.',
          value: 'maxWavelength',
        },
        {
          text: 'Max absorbance value recorded from the spectrum where at the strongest signal.',
          value: 'maximumAbs',
        },
        {text: 'None of these.', value: 'none'},
      ],
      items3: [
        {text: '$1 = \\text{A C + }\\epsilon$', value: 'ACe'},
        {text: '$\\text{A = }\\epsilon~\\text{l C}$', value: 'elC'},
        {text: '$\\text{C = }\\epsilon~\\text{l A}$', value: 'elA'},
        {text: '$\\text{A = }\\epsilon~\\text{C +} 1$', value: 'ec1'},
      ],
      items4: [
        {text: '$\\epsilon$', value: 'e'},
        {text: '$\\epsilon~\\text{l}$', value: 'el'},
        {text: '$\\text{A C}$', value: 'AC'},
        {text: '$\\text{C}$', value: 'C'},
        {text: '$0$', value: 'zero'},
        {text: '$\\text{A}$', value: 'A'},
        {text: '$\\text{I}$', value: 'I'},
      ],
      items5: [
        {text: 'Less than', value: 'less'},
        {text: 'Equal to', value: 'equal'},
        {text: 'Greater than', value: 'greater'},
        {text: 'Not related to', value: 'notRelated'},
      ],
      items6: [
        {text: 'The absorbance is the same for both dyes', value: 'same'},
        {text: 'The first one', value: 'first'},
        {text: 'The second one', value: 'second'},
        {text: 'Not enough information is given', value: 'info'},
      ],
      items7: [
        {text: 'A lambda max at a red wavelength (670 nm)', value: 'red'},
        {
          text: 'Two lambda maxes, one in the red (700 nm) and one in the orange (620 nm)',
          value: 'redOrange',
        },
        {text: 'A lambda max at a green wavelength (520 nm)', value: 'green'},
        {
          text: 'Two lambda maxes, one in the blue (470 nm) and one in the yellow (560 nm)',
          value: 'blueYellow',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
