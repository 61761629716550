var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v(" An absorption spectrum is a plot of "),_c('b',[_vm._v(" [A] ")]),_vm._v(" vs "),_c('b',[_vm._v(" [B]")]),_vm._v(". "),_c('stemble-latex',{attrs:{"content":"$\\lambda_\\text{max}$"}}),_vm._v(" is the "),_c('b',[_vm._v(" [C]")]),_vm._v(". ")],1),_c('p',{staticClass:"mb-2 pl-6"},[_vm._v(" [A]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2 pl-6"},[_vm._v(" [B]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-6 pl-6"},[_vm._v(" [C]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" A Beer's Law plot is a plot of "),_c('b',[_vm._v(" [D] ")]),_vm._v(" vs "),_c('b',[_vm._v(" [E]")]),_vm._v(". Beer's Law is "),_c('b',[_vm._v(" [F] ")]),_vm._v(" The slope of Beer's Law is "),_c('b',[_vm._v(" [G] ")]),_vm._v(" and the y-intercept is "),_c('b',[_vm._v(" [H]")]),_vm._v(". ")]),_c('p',{staticClass:"mb-2 pl-6"},[_vm._v(" [D]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-2 pl-6"},[_vm._v(" [E]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',{staticClass:"mb-2 pl-6"},[_vm._v(" [F]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('p',{staticClass:"mb-2 pl-6"},[_vm._v(" [G]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1),_c('p',{staticClass:"mb-6 pl-6"},[_vm._v(" [H]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" If the absorbance is measured at a "),_c('stemble-latex',{attrs:{"content":"$\\lambda$"}}),_vm._v(" different than "),_c('stemble-latex',{attrs:{"content":"$\\lambda_\\text{max}$,"}}),_vm._v(" the absorbance at that different "),_c('stemble-latex',{attrs:{"content":"$\\lambda$"}}),_vm._v(" is "),_c('b',[_vm._v(" [I] ")]),_vm._v(" the absorbance at "),_c('stemble-latex',{attrs:{"content":"$\\lambda_\\text{max}$."}})],1),_c('p',{staticClass:"mb-6 pl-6"},[_vm._v(" [I]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input9),callback:function ($$v) {_vm.$set(_vm.inputs, "input9", $$v)},expression:"inputs.input9"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" If the concentration of the first sample of dye 1 is less than the concentration of a second sample of dye 1, which sample has the highest absorbance? ")]),_c('p',{staticClass:"mb-6 pl-6"},[_vm._v(" j) "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input10),callback:function ($$v) {_vm.$set(_vm.inputs, "input10", $$v)},expression:"inputs.input10"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" If a solution contains a yellow dye and a blue dye, the absorption spectrum will contain "),_c('b',[_vm._v(" [K]")]),_vm._v(". ")]),_c('p',{staticClass:"mb-3 pl-6"},[_vm._v(" [K]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items7,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input11),callback:function ($$v) {_vm.$set(_vm.inputs, "input11", $$v)},expression:"inputs.input11"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }